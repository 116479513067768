/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 17/08/2023.
 */
import React from 'react'
import { useConfig } from '../hooks/useConfig'
import { PROJECT_REF } from '../constants'

type Props = { label: string; onClick?: () => void; style?: React.CSSProperties }

export const Button = ({ label, onClick, style = {} }: Props) => {
  const { data } = useConfig(PROJECT_REF as string)

  const clikker = () => {
    if (onClick) {
      onClick()
    } else {
      console.log('Button > no click handler = ')
    }
  }

  return (
    <button
      onClick={clikker}
      className="btn btn-primary py-3 py-md-3 px-6 px-md-7 mt-md-6 fw-bold"
      style={{ border: 'none', background: data.wheel.primaryColor, ...style }}
    >
      <p>{label}</p>
    </button>
  )
}
