export const BASE_URL = process.env.REACT_APP_BASE_URL
export const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL
export const PROJECT_REF = process.env.REACT_APP_PROJECT_REF

console.log('constants > PROJECT_TEST = ', PROJECT_REF)
console.log('constants > process.env. = ', process.env)

export const DEBUG: boolean = process.env.REACT_APP_DEBUG === 'true' ? true : false

export const DEFAULT_BGIMAGE =
  'https://images.unsplash.com/photo-1517245386807-9b4d0dd8eac1?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80'
