/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 16/08/2023.
 */
import React from 'react'
import { DEFAULT_BGIMAGE, S3_BUCKET_URL } from '../constants'
import { ConfigType } from '../Main'
import { Button } from '../components/Button'

type Props = {
  data: ConfigType
  navigate: (value: number) => void
  gotoPage: number
}

export const WelcomePage = ({ data, navigate, gotoPage }: Props) => {
  console.log(' WelcomePage > data = ', data)

  const prize = data?.prizeImage ? S3_BUCKET_URL + '/' + data.prizeImage : DEFAULT_BGIMAGE
  const prizeDescription = data.page.prizeDescription ? data.page.prizeDescription.trim() : ''

  const goToPage = () => {
    navigate(gotoPage)
  }

  return (
    <>
      {/*<Header data={data}></Header>*/}
      <div className="row h-100 d-flex flex-column justify-content-between justify-content-md-start  ">
        <div className="col-12 ">
          <p className="px-4 text-center ">{data.page.prize}</p>
        </div>

        <div className="col-12 ratio ratio-4x3 ">
          <div
            className="h-100 w-100"
            style={{
              backgroundImage: `url(${prize})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          ></div>
        </div>

        {prizeDescription ? (
          <div className="col-12 mb-5">
            <p className="text-center fw-bold  ">{data.page.prizeDescription}</p>
          </div>
        ) : null}

        <div className="col-12 mb-5 mb-md-5 d-flex justify-content-center  ">
          <Button onClick={goToPage} label={data.page.startBtn}></Button>
        </div>
        {/*<div className="col-12 col-md-4 col-md-8 mx-auto pb-6 ">
          <p className="fw-bold text-white text-center mt-3">##########</p>
          <p className="text-white text-center p-small opacity-75 mt-0 mt-md-2 px-4">
            ##### ##### #### # ### ## # ### ## ##### ### ## ##### ### ## ##### ### ## ##### ### ##
          </p>
        </div>*/}
      </div>
    </>
  )
}
